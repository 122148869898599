import * as Sentry from '@sentry/react'

export function initializeSentry() {
  // Note: sentry.io
  Sentry.init({
    dsn: 'https://2bcdb1be7bd1c59916d4dbcfbadd07c4@o4507442722242560.ingest.us.sentry.io/4507442725781504',
    // integrations: [
    //   Sentry.browserTracingIntegration(),
    //   Sentry.replayIntegration(),
    //   // Sentry.rewriteFramesIntegration({ root: global.__dirname }),
    // ],
    // // Performance Monitoring
    // tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    // // Session Replay
    // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    // replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}
