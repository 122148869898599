import { RemixBrowser } from '@remix-run/react'
import React from 'react'
import * as ReactDOM from 'react-dom/client'
import i18nInitialize from './lib/i18n'
import { initializeSentry } from './lib/sentry'

// if ((import.meta.env.VITE_USE_SENTRY || 'true') === 'true') {
initializeSentry()
// } else {
//   console.log(`Skipping Sentry in the client`)
// }

const hydrate = () => {
  i18nInitialize().then(() =>
    React.startTransition(() => {
      ReactDOM.hydrateRoot(document, <RemixBrowser />)
    }),
  )
}

if (window.requestIdleCallback) {
  window.requestIdleCallback(hydrate)
} else {
  // Safari doesn't support requestIdleCallback
  // https://caniuse.com/requestidlecallback
  window.setTimeout(hydrate, 1)
}
